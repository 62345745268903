import { Drawer, Image, Space, Spin, Upload, notification } from "antd";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import ErrorMessage from "components/error-message/ErrorMessage";
import RichTextEditor from "components/rich-text-Editor/RichTextEditor";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/blog/Actions";
import * as uploadActions from "redux/upload/Actions";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";
import {formatForDatetimeLocal} from "../../../utils/helpers/helper-functions"
function BlogDrawer(props) {
  const { Segment, language } = LanguageSegment();

  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;
  const record = editRecord;
  const { loading } = useSelector(
    (state) => ({
      loading: state.country?.loading,
    }),
    shallowEqual
  );
  const {
    title_en = "",
    title_ar = "",
    title_de = "",
    title_ru = "",
    title_uk = "",
    content_en = null,
    content_ar = null,
    content_de = null,
    content_ru = null,
    content_uk = null,
    image = null,
    createdAt="",
    id = null,
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = isEditing ? loading.update : loading.create;
  const title = `${view ? " View " : isEditing ? " Edit " : " Add "} Blog`;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(isEditing ? image : "");
  const [open, setOpen] = useState(false);

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
    setPreviewImage(image || "");
  };

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewImage("");
    setImage(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      resetForm();
      setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(
    () => {
      drawer && isEditing && setEditDefaultValues();
    },
    // eslint-disable-next-line
    [drawer]
  );

  //formik Handler
  const initialValues = {
    title_en: title_en || "",
    title_ar: title_ar || "",
    title_ru: title_ru || "",
    title_de: title_de || "",
    title_uk: title_uk || "",
    content_en: content_en || "",
    content_ar: content_ar || "",
    content_ru: content_ru || "",
    content_de: content_de || "",
    content_uk: content_uk || "",
    image: image || "",
    createdAt:createdAt|| null,
  };

  const validationSchema = Yup.object().shape({
    title_en: Yup.string().required("Title (English) is required"),
    title_ar: Yup.string().notRequired(),
    title_ru: Yup.string().notRequired(),
    title_de: Yup.string().notRequired(),
    title_uk: Yup.string().notRequired(),
    content_en: Yup.string().required("Content (English) is required"),
    content_ar: Yup.string().notRequired(),
    content_ru: Yup.string().notRequired(),
    content_de: Yup.string().notRequired(),
    content_uk: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
    createdAt:Yup.string().notRequired(),
    
  });

  const onSubmit = async (values) => {
    setOpen(false)
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    if (uploadedImage) {
      const Images = await dispatch(uploadActions.upload([uploadedImage]));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        dispatch(
          actions.loading(isEditing ? { update: false } : { create: false })
        );
        return;
      }
      values.image = Images[0] || "";
    }
    const action = isEditing
      ? actions.update(
          id,
          values,
          setDrawer,
          resetForm,
          pagination,
          setEditRecord
        )
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"60%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!isEditing && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div
          className="language-segmented   px-3  mb-2 "
          style={{
            padding: "20px 0",
            margin: 0,
            background: "#fff",
            border: "1px solid #dbdbdb",
            boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
            borderRadius: "10px",
          }}
        >
          {Segment}
        </div>
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="  my-2">
              {/* /********** INPUT ********/}
              {language === "en" && (
                <>
                  {" "}
                  <div className="row mx-3">
                    <div className="col mt-2"></div>
                  </div>
                  <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                      <div style={{ width: "100%" }}>
                      <span className="drawer_span required">
                          Tilte{" "}
                          <span className="form-label-secondary">
                            (English)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          placeholder="Enter Title in English"
                          name="title_en"
                          className={`form-control drawer_input ${
                            formik.errors.title_en && formik.touched.title_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title_en}
                        />
                        {formik.errors.title_en && formik.touched.title_en && (
                          <ErrorMessage message={formik.errors.title_en} />
                        )}
                      </div>
                    </div>
                  </div>
                   {/* /***************** Date time *******************/}
                   <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                   <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Blog Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    </div>
                    </div>
                  <div className="row mx-3">
                    <div className="col my-2 ">
                    <span className="drawer_span required">
                        Blog Content{" "}
                        <span className="form-label-secondary">
                          (English)
                        </span>
                      </span>
                      <RichTextEditor
                        value={formik.values.content_en}
                        name={content_en}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("content_en", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.content_en && formik.touched.content_en && (
                        <ErrorMessage message={formik.errors.content_en} />
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* /********** INPUT ********/}
              {language === "ar" && (
                <>
                  {" "}
                  <div className="row mx-3">
                    <div className="col mt-2"></div>
                  </div>
                  <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                      <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                          Tilte{" "}
                          <span className="form-label-secondary">
                            (Arabic)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          placeholder="Enter Title in Arabic"
                          name="title_ar"
                          className={`form-control drawer_input ${
                            formik.errors.title_ar && formik.touched.title_ar
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title_ar}
                        />
                        {formik.errors.title_ar && formik.touched.title_ar && (
                          <ErrorMessage message={formik.errors.title_ar} />
                        )}
                      </div>
                    </div>
                  </div>
                   {/* /***************** Date time *******************/}
                   <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                   <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Blog Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    </div>
                    </div>
                  <div className="row mx-3">
                    <div className="col my-2 ">
                    <span className="drawer_span ">
                        Blog Content{" "}
                        <span className="form-label-secondary">
                          (Arabic)
                        </span>
                      </span>
                      <RichTextEditor
                        value={formik.values.content_ar}
                        name={content_ar}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("content_ar", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.content_ar && formik.touched.content_ar && (
                        <ErrorMessage message={formik.errors.content_ar} />
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* /********** INPUT ********/}
              {language === "ru" && (
                <>
                  {" "}
                  <div className="row mx-3">
                    <div className="col mt-2"></div>
                  </div>
                  <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                      <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                          Tilte{" "}
                          <span className="form-label-secondary">
                            (Russian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          placeholder="Enter Title in Russian"
                          name="title_ru"
                          className={`form-control drawer_input ${
                            formik.errors.title_ru && formik.touched.title_ru
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title_ru}
                        />
                        {formik.errors.title_ru && formik.touched.title_ru && (
                          <ErrorMessage message={formik.errors.title_ru} />
                        )}
                      </div>
                    </div>
                  </div>
                   {/* /***************** Date time *******************/}
                   <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                   <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Blog Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    </div>
                    </div>
                  <div className="row mx-3">
                    <div className="col my-2 ">
                    <span className="drawer_span ">
                        Blog Content{" "}
                        <span className="form-label-secondary">
                          (Russian)
                        </span>
                      </span>
                      <RichTextEditor
                        value={formik.values.content_ru}
                        name={content_ru}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("content_ru", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.content_ru && formik.touched.content_ru && (
                        <ErrorMessage message={formik.errors.content_ru} />
                      )}
                    </div>
                  </div>
                </>
              )}
              {language === "de" && (
                <>
                  {" "}
                  <div className="row mx-3">
                    <div className="col mt-2"></div>
                  </div>
                  <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                      <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                          Tilte{" "}
                          <span className="form-label-secondary">
                            (German)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          placeholder="Enter Title in German"
                          name="title_de"
                          className={`form-control drawer_input ${
                            formik.errors.title_de && formik.touched.title_de
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title_de}
                        />
                        {formik.errors.title_de && formik.touched.title_de && (
                          <ErrorMessage message={formik.errors.title_de} />
                        )}
                      </div>
                    </div>
                  </div>
                   {/* /***************** Date time *******************/}
                   <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                   <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Blog Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    </div>
                    </div>
                  <div className="row mx-3">
                    <div className="col my-2 ">
                    <span className="drawer_span">
                        Blog Content{" "}
                        <span className="form-label-secondary">
                          (German)
                        </span>
                      </span>
                      <RichTextEditor
                        value={formik.values.content_de}
                        name={content_de}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("content_de", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.content_de && formik.touched.content_de && (
                        <ErrorMessage message={formik.errors.content_de} />
                      )}
                    </div>
                  </div>
                </>
              )}
              {language === "uk" && (
                <>
                  {" "}
                  <div className="row mx-3">
                    <div className="col mt-2"></div>
                  </div>
                  <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                      <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                          Tilte{" "}
                          <span className="form-label-secondary">
                            (Ukrainian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          placeholder="Enter Title in Ukrainian"
                          name="title_uk"
                          className={`form-control drawer_input ${
                            formik.errors.title_uk && formik.touched.title_uk
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title_uk}
                        />
                        {formik.errors.title_uk && formik.touched.title_uk && (
                          <ErrorMessage message={formik.errors.title_uk} />
                        )}
                      </div>
                    </div>
                  </div>
                   {/* /***************** Date time *******************/}
                   <div className="row mx-3">
                    {/* /***************** Title *******************/}
                    <div className="col d-flex">
                   <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Blog Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    </div>
                    </div>
                    
                  <div className="row mx-3">
                    <div className="col my-2 ">
                    <span className="drawer_span">
                        Blog Content{" "}
                        <span className="form-label-secondary">
                          (Ukrainian)
                        </span>
                      </span>
                      <RichTextEditor
                        value={formik.values.content_uk}
                        name={content_uk}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("content_uk", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.content_uk && formik.touched.content_uk && (
                        <ErrorMessage message={formik.errors.content_uk} />
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* /********** INPUT ********/}

              {/* Image */}
              <div className="row mx-3">
                <div
                  className="d-flex justify-content-center align-items-end  h-100 text-center"
                  style={{ width: "100%" }}
                >
                  <div>
                    <Image
                      width={100}
                      height={100}
                      preview={previewImage ? true : false}
                      src={previewImage || FALLBACK_IMAGE_URL}
                      fallback={FALLBACK_IMAGE_URL}
                    />

                    <div className="d-flex-custom mx-auto mt-2 justify-content-center">
                      {!view && (
                        <>
                          <Upload
                            showUploadList={false}
                            maxCount={1}
                            onChange={onChange}
                            disabled={view}
                          >
                            <button
                              type="button"
                              disabled={view}
                              className="btn-theme btn py-2 px-4"
                            >
                              Upload
                            </button>
                          </Upload>

                          <button
                            type="button"
                            style={{
                              background: previewImage ? "#f30301" : "grey",
                            }}
                            className="btn py-2 px-4 mx-2 text-white "
                            disabled={view ? true : previewImage ? false : true}
                            onClick={removeImage}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default BlogDrawer;
