import React, { useRef, useEffect, useState } from 'react';

const Tooltip = ({ data, setData, setHoveredTooltipId, tooltipPosition, openEventModal, hoveredTooltipId }) => {
  const tooltipRef = useRef(null); // Ref to measure tooltip dimensions
  const [adjustedPosition, setAdjustedPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (hoveredTooltipId && tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height;
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - tooltipPosition.top;
      const topPosition = spaceBelow < tooltipHeight
        ? tooltipPosition.top - tooltipHeight - 10 // Move above if not enough space below
        : tooltipPosition.top;

      setAdjustedPosition({
        top: topPosition,
        left: tooltipPosition.left,
      });
    }
  }, [hoveredTooltipId, tooltipPosition]);

  const handleMouseLeave = () => {
    setData({});
    setHoveredTooltipId(null);
  };

  return (
    hoveredTooltipId && data?.id === hoveredTooltipId && (
      <div
        ref={tooltipRef}
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'absolute',
          top: adjustedPosition.top,
          left: adjustedPosition.left,
          padding: '5px 10px',
          backgroundColor: 'white',
          color: '#000',
          border: '1px solid #000',
          borderRadius: '6px',
          transition: 'opacity 0.3s',
          zIndex: 1000,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          whiteSpace: 'nowrap',
          right: data?.requestEvent?.length === 0 && '200px',
        }}
      >
        {data?.requestEvent && data?.requestEvent?.length > 0 ? (
          data?.requestEvent?.slice(-10)?.map((event, index) => (
            <div key={index} style={{ marginBottom: '5px' }}>
              <span style={{ marginRight: '5px', color: 'black' }}>•</span>
              <span>
                {new Date(`${event.date}T${event.time}Z`).toLocaleDateString()}{" "}
                {new Date(`${event.date}T${event.time}Z`).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}{" "}
                - {event.status.replace("-", " ")}
              </span>
            </div>
          ))
        ) : (
          <span className="no-event">No Event Yet</span>
        )}

        <button
          type="button"
          onClick={(e) => openEventModal(e, data)}
          title="Event"
          className="btn btn-link"
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        >
          + Add Event
        </button>
      </div>
    )
  );
};

export default Tooltip;
