import { PlusOutlined } from "@ant-design/icons";
import { Drawer, Modal, Space, Spin, Upload, notification } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import RichTextEditor from "components/rich-text-Editor/RichTextEditor";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/testimonials/Actions";
import * as Yup from "yup";
import CountrySelect from "components/country-select/CountrySelect";
import * as CountryAction from "redux/country/Actions";
import * as facilityActions from "redux/facility/Actions";
import * as doctorActions from "redux/doctor/Actions";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";
import * as uploadActions from "redux/upload/Actions";

function TestimonialsDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;
  const record = editRecord;
  const { Segment, language } = LanguageSegment();

  const dispatch = useDispatch();
  const pagination = getPagination();

  const { countriesRecords, loading, doctor, facility } = useSelector(
    (state) => ({
      countriesRecords:state.country?.allCountriesRecords || [],
      loading: state.country?.loading,
      doctor: state.doctor?.records,
      facility: state.facility?.records,
    }),
    shallowEqual
  );

  const {
    clientName_en = "",
    clientName_ar = "",
    clientName_de = "",
    clientName_ru = "",
    clientName_uk = "",

    clientDesignation_en = "",
    clientDesignation_ar = "",
    clientDesignation_de = "",
    clientDesignation_ru = "",
    clientDesignation_uk = "",

    companyName_en = "",
    companyName_ar = "",
    companyName_de = "",
    companyName_ru = "",
    companyName_uk = "",

    companyType_en = "",
    companyType_ar = "",
    companyType_de = "",
    companyType_ru = "",
    companyType_uk = "",

    companyWebsiteLink = "",
    countryId = editRecord ? editRecord?.country?.id : countriesRecords[0]?.id,
    // name_en = "us",
    reviewText_en = "",
    reviewText_ar = "",
    reviewText_de = "",
    reviewText_ru = "",
    reviewText_uk = "",

    reviewType = "",
    reviewSource="",
    createdAt="",
    videoLink = "",
    isFeatured = "",
    isApproved = "",
    userId = null,
    id = null,
    rating = null,
    facilityId = null,
    doctorId = null,
  } = record || {};
  
  useEffect(() => {
    if (countriesRecords?.length > 0 && !editRecord) {
      formik.setFieldValue("countryId", countriesRecords[0]?.id);
    }
  }, [countriesRecords, editRecord]);
  
  const isEditing = Boolean(record);
  const isLoading = isEditing ? loading.update : loading.create;
  const title = `${
    view ? " View " : isEditing ? " Edit " : " Add "
  } Testimonial`;
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState(null);
  // const [country, setCountry] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  // const [uploadedFile, setUploadedFile] = useState(null);
  const [uploaderLoading, setUploaderLoading] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [reviewFrom, setReviewFrom] = useState(false);
  const [showErrorReviewFrom, setShowErrorReviewFrom] = useState(false);

  useEffect(() => {
    reviewFrom && setShowErrorReviewFrom(false);
  }, [reviewFrom]);

  useEffect(() => {
    if (editRecord && editRecord.facilityId && editRecord.facilityId !== null) {
      setReviewFrom("Facility");
    } else if (
      editRecord &&
      editRecord.doctorId &&
      editRecord.doctorId !== null
    ) {
      setReviewFrom("Doctor");
    }
  }, [editRecord]);
  const formatForDatetimeLocal = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const handleUpload = async (file) => {
    try {
      setUploaderLoading(true);
  
      if (!file) {
        notification.error({ description: "No file selected for upload." });
        setUploaderLoading(false);
        return;
      }
  
      // Upload the video
      const videos = await dispatch(uploadActions.upload([file]));
  
      if (!videos?.length) {
        notification.error({ description: "Video Upload failed." });
      } else {
        // Successfully uploaded
        formik.setFieldValue("videoLink", videos[0]);
        setPreviewUrl(videos[0]);
        notification.success({ description: "Video Uploaded successfully." });
      }
    } catch (error) {
      console.error("Upload error:", error);
      notification.error({ description: "An error occurred during upload." });
    } finally {
      setUploaderLoading(false);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };
  
  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewOpen(false);
    setPreviewUrl(null);
    // formik.setFieldValue("countryId", countriesRecords[0]?.id);
    formik.setFieldValue(
      "countryId",
      editRecord?.country?.id || countriesRecords[0]?.id
    );
    setCode(countriesRecords[0]?.code);
    setReviewFrom(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      resetForm();
      setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const getMasterData = () => {
    dispatch(
      doctorActions.getAll({
        query:
          "limit=1000000&offset=0&select[firstName_en]=true&select[firstName_ar]=true&select[firstName_de]=true&select[firstName_ru]=true&select[firstName_uk]=true&select[lastName_en]=true&select[lastName_ar]=true&select[lastName_de]=true&select[lastName_ru]=true&select[lastName_uk]=true&select[id]=true",
      })
    );
    dispatch(
      facilityActions.getAll({
        query:
          "limit=1000000&offset=0&select[name_en]=true&select[name_ar]=true&select[name_de]=true&select[name_ru]=true&select[name_uk]=true&select[id]=true",
      })
    );
    dispatch(
      CountryAction.getAllTheCountries({
        query:
          "limit=1000000&offset=0&select[name_en]=true&select[name_ar]=true&select[name_de]=true&select[name_ru]=true&select[name_uk]=true&select[id]=true&select[code]=true",
      })
    );
  };

  useEffect(
    () => {
      drawer && isEditing && setEditDefaultValues();
      drawer && getMasterData();
    },
    // eslint-disable-next-line
    [drawer]
  );

  const initialValues = {
    clientName_en: clientName_en || "",
    clientName_ar: clientName_ar || "",
    clientName_de: clientName_de || "",
    clientName_ru: clientName_ru || "",
    clientName_uk: clientName_uk || "",

    clientDesignation_en: clientDesignation_en || "",
    clientDesignation_ar: clientDesignation_ar || "",
    clientDesignation_de: clientDesignation_de || "",
    clientDesignation_ru: clientDesignation_ru || "",
    clientDesignation_uk: clientDesignation_uk || "",

    companyName_en: companyName_en || "",
    companyName_ar: companyName_ar || "",
    companyName_de: companyName_de || "",
    companyName_ru: companyName_ru || "",
    companyName_uk: companyName_uk || "",

    companyType_en: companyType_en || "",
    companyType_ar: companyType_ar || "",
    companyType_de: companyType_de || "",
    companyType_ru: companyType_ru || "",
    companyType_uk: companyType_uk || "",

    companyWebsiteLink: companyWebsiteLink || "",
    countryId: countryId || "",
    reviewText_en: reviewText_en || "",
    reviewText_ar: reviewText_ar || "",
    reviewText_de: reviewText_de || "",
    reviewText_ru: reviewText_ru || "",
    reviewText_uk: reviewText_uk || "",

    reviewType: reviewType || "text",
    reviewSource:reviewSource||"self",
    createdAt:createdAt|| null,
    videoLink: videoLink || "",
    isFeatured: isFeatured || false,
    isApproved: isApproved || false,
    userId: userId || null,
    rating: rating || null,
    facilityId: facilityId || null,
    doctorId: doctorId || null,
  };

  const validationSchema = Yup.object().shape({
    clientName_en: Yup.string().required("Client name is required"),
    clientName_ar: Yup.string().notRequired(),
    clientName_de: Yup.string().notRequired(),
    clientName_ru: Yup.string().notRequired(),
    clientName_uk: Yup.string().notRequired(),

    clientDesignation_en:Yup.string().notRequired(),
    clientDesignation_ar: Yup.string().notRequired(),
    clientDesignation_de: Yup.string().notRequired(),
    clientDesignation_ru: Yup.string().notRequired(),
    clientDesignation_uk: Yup.string().notRequired(),

    companyName_en: Yup.string().notRequired(),
    companyName_ar: Yup.string().notRequired(),
    companyName_de: Yup.string().notRequired(),
    companyName_ru: Yup.string().notRequired(),
    companyName_uk: Yup.string().notRequired(),

    companyType_en: Yup.string().notRequired(),
    companyType_ar: Yup.string().notRequired(),
    companyType_de: Yup.string().notRequired(),
    companyType_ru: Yup.string().notRequired(),
    companyType_uk: Yup.string().notRequired(),

    companyWebsiteLink: Yup.string().notRequired(),
    countryId: Yup.string().required("Country is required"),
    reviewText_en: Yup.string().required("Review text is required"),
    reviewText_ar: Yup.string().notRequired(),
    reviewText_de: Yup.string().notRequired(),
    reviewText_ru: Yup.string().notRequired(),
    reviewText_uk: Yup.string().notRequired(),

    reviewType: Yup.string().required("Review type is required"),
    reviewSource:Yup.string().notRequired(),
    createdAt:Yup.string().notRequired(),
    videoLink: Yup.string().notRequired().nullable(),
    isFeatured: Yup.string().notRequired(),
    isApproved: Yup.string().notRequired(),
    rating: Yup.number()
      .required("Rating is required")
      .oneOf(
        [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
        "Invalid rating value"
      )
      .typeError("Rating must be a number"),
  });

  const onSubmit = async (values) => {
    setOpen(false);
    if (values.reviewType === "text") {
      values.videoLink = "";
    }
    if (!reviewFrom || reviewFrom === null) {
      setShowErrorReviewFrom(true);
      return;
    } else {
      setShowErrorReviewFrom(false);
    }
    if (
      reviewFrom === "Facility" &&
      (!values.facilityId || values.facilityId === null)
    ) {
      formik.setFieldError("facilityId", "Facility is required");
      return;
    } else if (
      reviewFrom === "Doctor" &&
      (!values.doctorId || values.doctorId === null)
    ) {
      formik.setFieldError("doctorId", "Doctor is required");
      return;
    } else {
      formik.setFieldError("facilityId", "");
      formik.setFieldError("doctorId", "");
    }
   
    values.rating = parseFloat(values.rating);
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    const action = isEditing
      ? actions.update(
          id,
          values,
          setDrawer,
          resetForm,
          pagination,
          setEditRecord
        )
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  useEffect(
    () => {
      if (countriesRecords && editRecord && !reviewFrom) {
        //instead of code checking reviewFrom as code is sets to 0th index values on reset
        setCode(editRecord?.country?.code || countriesRecords[0]?.code);
      }
      if (countriesRecords && !editRecord && !code) {
        formik.setFieldValue("countryId", countriesRecords[0]?.id);
      }
    },
    // eslint-disable-next-line
    [editRecord, language]
  );

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"60%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!isEditing && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading || uploaderLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm();
                    console.log("errors:",errors)
                    if (Object.keys(errors).length === 0) {
                      setOpen(true);
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce(
                          (acc, field) => ({ ...acc, [field]: true }),
                          {}
                        )
                      );
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading || uploaderLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : uploaderLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div
          className="language-segmented px-3  mb-2 "
          style={{
            padding: "20px 0",
            margin: 0,
            background: "#fff",
            border: "1px solid #dbdbdb",
            boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
            borderRadius: "10px",
          }}
        >
          {Segment}
        </div>

        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="  my-2">
              <div className="row mx-3">
                <div className="col mt-2"></div>
              </div>
              <div className="row mx-3">
                {/* /********** INPUT ********/}
                {language === "en" && (
                  <>
                    {" "}
                    <div className="row mx-3">
                      <div className="col mt-2"></div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span required">
                        Client Full Name{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="clientName_en"
                        className={`form-control ${
                          formik.errors.clientName_en &&
                          formik.touched.clientName_en
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName_en}
                        placeholder="Enter client full name in English"
                      />
                      {formik.errors.clientName_en &&
                        formik.touched.clientName_en && (
                          <ErrorMessage message={formik.errors.clientName_en} />
                        )}
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Designation{" "}
                          <span className="form-label-secondary">
                            (English)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="clientDesignation_en"
                          className={`form-control ${
                            formik.errors.clientDesignation_en &&
                            formik.touched.clientDesignation_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.clientDesignation_en}
                          placeholder="Enter designation in English"
                        />
                        {formik.errors.clientDesignation_en &&
                          formik.touched.clientDesignation_en && (
                            <ErrorMessage
                              message={formik.errors.clientDesignation_en}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Company Name{" "}
                          <span className="form-label-secondary">
                            (English)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyName_en"
                          className={`form-control ${
                            formik.errors.companyName_en &&
                            formik.touched.companyName_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName_en}
                          placeholder="Enter Company Name in English"
                        />
                        {formik.errors.companyName_en &&
                          formik.touched.companyName_en && (
                            <ErrorMessage
                              message={formik.errors.companyName_en}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                        Company Type{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="companyType_en"
                        className={`form-control ${
                          formik.errors.companyType_en &&
                          formik.touched.companyType_en
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyType_en}
                        placeholder="Enter what the company is about in English"
                      />
                      {formik.errors.companyType_en &&
                        formik.touched.companyType_en && (
                          <ErrorMessage
                            message={formik.errors.companyType_en}
                          />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Company Website Link
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyWebsiteLink"
                          className={`form-control ${
                            formik.errors.companyWebsiteLink &&
                            formik.touched.companyWebsiteLink
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyWebsiteLink}
                          placeholder="Enter link of company website"
                        />
                        {formik.errors.companyWebsiteLink &&
                          formik.touched.companyWebsiteLink && (
                            <ErrorMessage
                              message={formik.errors.companyWebsiteLink}
                            />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Country</span>
                        <CountrySelect
                          values={{
                            value: code || countriesRecords[0]?.code || "",
                            label: (
                              <div>
                                {" "}
                                <img
                                  className="avatar avatar-xss avatar-circle me-2"
                                  src={`assets_modules/flag-icon-css/flags/1x1/${
                                    code?.toLowerCase() ||
                                    editRecord?.country?.code?.toLowerCase() ||
                                    countriesRecords[0]?.code?.toLowerCase()
                                  }.svg`}
                                  alt="Country Flag"
                                />
                                {(code &&
                                  countriesRecords.find(
                                    (country) => country.code === code
                                  )?.[`name_${language}`]) ||
                                  countriesRecords[0]?.name_en}
                              </div>
                            ),
                          }}
                          defaultNameRedux={""}
                          handleBlur={formik.handleBlur}
                          defaultValueRedux={""}
                          setCountry={(e) => {
                            formik.setFieldValue("countryId", e?.id || "");
                            // setCountry(e?.value || "");
                            setCode(
                              e?.label?.props?.children[0]?.props?.alt || ""
                            );
                          }}
                          className={
                            "w-100" +
                            ` ${
                              formik.errors.countryId &&
                              formik.touched.countryId
                                ? "input_error"
                                : ""
                            }`
                          }
                          style={{
                            border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                          }}
                          missingRecords={countriesRecords}
                          isDisabled={view}
                          language={language}
                        />
                        {formik.errors.countryId &&
                          formik.touched.countryId && (
                            <ErrorMessage message={formik.errors.countryId} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                     {/* /***************** Date time *******************/}
                     <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                     {/* /***************** Title *******************/}
                     <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Source</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewSource"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewSource", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewSource || "self"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Source
                          </option>
                          <option value="self">Best Clinic Aboard</option>
                          <option value="google">Google</option>
                          <option value="trustpilot">Trustpilot</option>

                        </select>
                        {formik.errors.reviewSource &&
                          formik.touched.reviewSource && (
                            <ErrorMessage message={formik.errors.reviewSource} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Type</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewType"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewType", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewType || "text"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Type
                          </option>
                          <option value="text">Written Testimonials</option>
                          <option value="video">Video Testimonials</option>
                        </select>
                        {formik.errors.reviewType &&
                          formik.touched.reviewType && (
                            <ErrorMessage message={formik.errors.reviewType} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {formik.values.reviewType === "video" && (
                      <div className="mb-5 pb-4">
                        <span className="pb-2  drawer_span">
                          Upload Video Testimonials
                          {previewUrl && !uploaderLoading && (
                            <button
                              className="btn btn-outline-dark mx-3"
                              type="button"
                              onClick={() => setPreviewOpen(true)}
                            >
                              Preview Video
                            </button>
                          )}
                        </span>
                        <div
                          className="d-flex align-items-center h-75  text-center"
                          style={{
                            border: "dotted 3px  grey",
                            placeContent: "center",
                          }}
                        >
                          <Upload
                            disabled={view}
                            action=""
                            customRequest={({ file }) => handleUpload(file)}
                            showUploadList={false}
                          >
                            {uploadButton}
                            {uploaderLoading && (
                              <>
                                {" "}
                                <Spin />{" "}
                                <span>Uploading Video, Please Wait</span>{" "}
                              </>
                            )}
                          </Upload>

                          <Modal
                            title="Preview Video"
                            visible={previewOpen}
                            footer={null}
                            onCancel={handleCancel}
                            bodyStyle={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "70vh",
                            }}
                          >
                            <video
                              src={previewUrl}
                              controls
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </Modal>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">Rating</span>
                        <select
                          disabled={view}
                          className={`form-control ${
                            formik.errors.rating && formik.touched.rating
                              ? "input_error"
                              : ""
                          }`}
                          name="rating"
                          onChange={(e) => {
                            formik.setFieldValue("rating", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            parseFloat(formik.values.rating) ||
                            parseFloat(editRecord?.rating) ||
                            ""
                          }
                        >
                          <option value="">Select rating</option>
                          <option value={0.5}>0.5</option>
                          <option value={1.0}>1.0</option>
                          <option value={1.5}>1.5</option>
                          <option value={2.0}>2.0</option>
                          <option value={2.5}>2.5</option>
                          <option value={3.0}>3.0</option>
                          <option value={3.5}>3.5</option>
                          <option value={4.0}>4.0</option>
                          <option value={4.5}>4.5</option>
                          <option value={5.0}>5.0</option>
                        </select>
                        {formik.errors.rating && formik.touched.rating && (
                          <ErrorMessage message={formik.errors.rating} />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial From</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewFrom"
                          onChange={(e) => {
                            setReviewFrom(e.target.value);
                          }}
                          value={reviewFrom || ""}
                        >
                          <option value="">Select Testimonials From</option>
                          <option value="Facility">Facility</option>
                          <option value="Doctor">Doctor</option>
                        </select>
                        {showErrorReviewFrom && (
                          <ErrorMessage
                            message={"Testimonials From is required"}
                          />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      {reviewFrom === "Facility" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Facility</span>
                          <select
                            disabled={view}
                            className="form-control drawer_input"
                            name="facilityId"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "facilityId",
                                e.target.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.facilityId}
                          >
                            <option value={null}> Select Facility</option>
                            {facility?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.name_en}
                              </option>
                            ))}
                          </select>
                          {formik.errors.facilityId &&
                            formik.touched.facilityId && (
                              <ErrorMessage
                                message={formik.errors.facilityId}
                              />
                            )}
                        </div>
                      )}
                      {/* /********** INPUT ********/}
                      {reviewFrom === "Doctor" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Doctor</span>
                          <select
                            defaultValue={null}
                            disabled={view}
                            className="form-control drawer_input"
                            name="doctorId"
                            onChange={(e) => {
                              const selectedDoctorId =
                                e.target.value === "null"
                                  ? null
                                  : e.target.value;
                              formik.setFieldValue(
                                "doctorId",
                                selectedDoctorId
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.doctorId}
                          >
                            <option value={null}> Select Doctor</option>
                            {doctor?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.firstName_en +
                                  " " +
                                  record?.lastName_en}
                              </option>
                            ))}
                          </select>
                          {formik.errors.doctorId &&
                            formik.touched.doctorId && (
                              <ErrorMessage message={formik.errors.doctorId} />
                            )}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    {/* /***************** isApproved *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isApproved"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isApproved}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isApproved &&
                              formik.touched.isApproved
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isApproved",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isApproved}
                          />
                          <span className="drawer_span">
                            Is Approved{" "}
                            <span className="form-label-secondary"></span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isApproved &&
                        formik.touched.isApproved && (
                          <ErrorMessage message={formik.errors.isApproved} />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** isFeatured *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isFeatured"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isFeatured}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isFeatured &&
                              formik.touched.isFeatured
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isFeatured",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isFeatured}
                          />
                          <span className="drawer_span">
                            Is Featured{" "}
                            <span className="form-label-secondary">
                              (Display on navbar ?)
                            </span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                    {/*  */}
                    <div style={{ width: "100%" }}>
                      <span className="pb-2 drawer_span required">
                        Testimonial Content{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <RichTextEditor
                        value={formik.values.reviewText_en}
                        name="reviewText_en"
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("reviewText_en", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.reviewText_en &&
                        formik.touched.reviewText_en && (
                          <ErrorMessage message={formik.errors.reviewText_en} />
                        )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "ar" && (
                  <>
                    {" "}
                    <div className="row mx-3">
                      <div className="col mt-2"></div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span required">
                        Client Full Name{" "}
                        <span className="form-label-secondary">(Arabic)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="clientName_ar"
                        className={`form-control ${
                          formik.errors.clientName_ar &&
                          formik.touched.clientName_ar
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName_ar}
                        placeholder="Enter client full name in Arabic"
                      />
                      {formik.errors.clientName_ar &&
                        formik.touched.clientName_ar && (
                          <ErrorMessage message={formik.errors.clientName_ar} />
                        )}
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Designation{" "}
                          <span className="form-label-secondary">(Arabic)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="clientDesignation_ar"
                          className={`form-control ${
                            formik.errors.clientDesignation_ar &&
                            formik.touched.clientDesignation_ar
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.clientDesignation_ar}
                          placeholder="Enter designation in Arabic"
                        />
                        {formik.errors.clientDesignation_ar &&
                          formik.touched.clientDesignation_ar && (
                            <ErrorMessage
                              message={formik.errors.clientDesignation_ar}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Company Name{" "}
                          <span className="form-label-secondary">(Arabic)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyName_ar"
                          className={`form-control ${
                            formik.errors.companyName_ar &&
                            formik.touched.companyName_ar
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName_ar}
                          placeholder="Enter Company Name in Arabic"
                        />
                        {formik.errors.companyName_ar &&
                          formik.touched.companyName_ar && (
                            <ErrorMessage
                              message={formik.errors.companyName_ar}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                        Company Type{" "}
                        <span className="form-label-secondary">(Arabic)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="companyType_ar"
                        className={`form-control ${
                          formik.errors.companyType_ar &&
                          formik.touched.companyType_ar
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyType_ar}
                        placeholder="Enter what the company is about in Arabic"
                      />
                      {formik.errors.companyType_ar &&
                        formik.touched.companyType_ar && (
                          <ErrorMessage
                            message={formik.errors.companyType_ar}
                          />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Company Website Link
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyWebsiteLink"
                          className={`form-control ${
                            formik.errors.companyWebsiteLink &&
                            formik.touched.companyWebsiteLink
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyWebsiteLink}
                          placeholder="Enter link of company website"
                        />
                        {formik.errors.companyWebsiteLink &&
                          formik.touched.companyWebsiteLink && (
                            <ErrorMessage
                              message={formik.errors.companyWebsiteLink}
                            />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Country</span>
                        <CountrySelect
                          values={{
                            value: code || countriesRecords[0]?.code || "",
                            label: (
                              <div>
                                {" "}
                                <img
                                  className="avatar avatar-xss avatar-circle me-2"
                                  src={`assets_modules/flag-icon-css/flags/1x1/${
                                    code?.toLowerCase() ||
                                    editRecord?.country?.code ||
                                    countriesRecords[0]?.code
                                  }.svg`}
                                  alt=" Country Flag"
                                />
                                {/* {country || countriesRecords[0]?.name_ar} */}
                                {(code &&
                                  countriesRecords.find(
                                    (country) => country.code === code
                                  )?.[`name_${language}`]) ||
                                  countriesRecords[0]?.name_ar}
                              </div>
                            ),
                          }}
                          defaultNameRedux={""}
                          handleBlur={formik.handleBlur}
                          defaultValueRedux={""}
                          setCountry={(e) => {
                            formik.setFieldValue("countryId", e?.id || "");
                            // setCountry(e?.value || "");
                            setCode(
                              e?.label?.props?.children[0]?.props?.alt || ""
                            );
                          }}
                          className={
                            "w-100" +
                            ` ${
                              formik.errors.countryId &&
                              formik.touched.countryId
                                ? "input_error"
                                : ""
                            }`
                          }
                          style={{
                            border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                          }}
                          missingRecords={countriesRecords}
                          isDisabled={view}
                          language={language}
                        />
                        {formik.errors.countryId &&
                          formik.touched.countryId && (
                            <ErrorMessage message={formik.errors.countryId} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Date time *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Source</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewSource"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewSource", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewSource || "self"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Source
                          </option>
                          <option value="self">Best Clinic Aboard</option>
                          <option value="google">Google</option>
                          <option value="trustpilot">Trustpilot</option>

                        </select>
                        {formik.errors.reviewSource &&
                          formik.touched.reviewSource && (
                            <ErrorMessage message={formik.errors.reviewSource} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Type</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewType"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewType", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewType || "text"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Type
                          </option>
                          <option value="text">Written Testimonials</option>
                          <option value="video">Video Testimonials</option>
                        </select>
                        {formik.errors.reviewType &&
                          formik.touched.reviewType && (
                            <ErrorMessage message={formik.errors.reviewType} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {formik.values.reviewType === "video" && (
                      <div className="mb-5 pb-4">
                        <span className="pb-2  drawer_span">
                          Upload Video Testimonials
                          {previewUrl && !uploaderLoading && (
                            <button
                              className="btn btn-outline-dark mx-3"
                              type="button"
                              onClick={() => setPreviewOpen(true)}
                            >
                              Preview Video
                            </button>
                          )}
                        </span>
                        <div
                          className="d-flex align-items-center h-75  text-center"
                          style={{
                            border: "dotted 3px  grey",
                            placeContent: "center",
                          }}
                        >
                          <Upload
                            disabled={view}
                            action=""
                            customRequest={({ file }) => handleUpload(file)}
                            showUploadList={false}
                          >
                            {uploadButton}
                            {uploaderLoading && (
                              <>
                                {" "}
                                <Spin />{" "}
                                <span>Uploading Video, Please Wait</span>{" "}
                              </>
                            )}
                          </Upload>

                          <Modal
                            title="Preview Video"
                            visible={previewOpen}
                            footer={null}
                            onCancel={handleCancel}
                            bodyStyle={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "70vh",
                            }}
                          >
                            <video
                              src={previewUrl}
                              controls
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </Modal>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">Rating</span>
                        <select
                          disabled={view}
                          className={`form-control ${
                            formik.errors.rating && formik.touched.rating
                              ? "input_error"
                              : ""
                          }`}
                          name="rating"
                          onChange={(e) => {
                            formik.setFieldValue("rating", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            parseFloat(formik.values.rating) ||
                            parseFloat(editRecord?.rating) ||
                            ""
                          }
                        >
                          <option value="">Select rating</option>
                          <option value={0.5}>0.5</option>
                          <option value={1.0}>1.0</option>
                          <option value={1.5}>1.5</option>
                          <option value={2.0}>2.0</option>
                          <option value={2.5}>2.5</option>
                          <option value={3.0}>3.0</option>
                          <option value={3.5}>3.5</option>
                          <option value={4.0}>4.0</option>
                          <option value={4.5}>4.5</option>
                          <option value={5.0}>5.0</option>
                        </select>
                        {formik.errors.rating && formik.touched.rating && (
                          <ErrorMessage message={formik.errors.rating} />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span ">Testimonial From</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewFrom"
                          onChange={(e) => {
                            setReviewFrom(e.target.value);
                          }}
                          value={reviewFrom || ""}
                        >
                          <option value="">Select Testimonials From</option>
                          <option value="Facility">Facility</option>
                          <option value="Doctor">Doctor</option>
                        </select>
                        {showErrorReviewFrom && (
                          <ErrorMessage
                            message={"Testimonials From is required"}
                          />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      {reviewFrom === "Facility" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Facility</span>
                          <select
                            disabled={view}
                            className="form-control drawer_input"
                            name="facilityId"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "facilityId",
                                e.target.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.facilityId}
                          >
                            <option value={null}> Select Facility</option>
                            {facility?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.name_ar}
                              </option>
                            ))}
                          </select>
                          {formik.errors.facilityId &&
                            formik.touched.facilityId && (
                              <ErrorMessage
                                message={formik.errors.facilityId}
                              />
                            )}
                        </div>
                      )}
                      {/* /********** INPUT ********/}
                      {reviewFrom === "Doctor" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Doctor</span>
                          <select
                            defaultValue={null}
                            disabled={view}
                            className="form-control drawer_input"
                            name="doctorId"
                            onChange={(e) => {
                              const selectedDoctorId =
                                e.target.value === "null"
                                  ? null
                                  : e.target.value;
                              formik.setFieldValue(
                                "doctorId",
                                selectedDoctorId
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.doctorId}
                          >
                            <option value={null}> Select Doctor</option>
                            {doctor?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.firstName_ar +
                                  " " +
                                  record?.lastName_ar}
                              </option>
                            ))}
                          </select>
                          {formik.errors.doctorId &&
                            formik.touched.doctorId && (
                              <ErrorMessage message={formik.errors.doctorId} />
                            )}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    {/* /***************** isApproved *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isApproved"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isApproved}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isApproved &&
                              formik.touched.isApproved
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isApproved",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isApproved}
                          />
                          <span className="drawer_span">
                            Is Approved{" "}
                            <span className="form-label-secondary"></span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isApproved &&
                        formik.touched.isApproved && (
                          <ErrorMessage message={formik.errors.isApproved} />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isFeatured"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isFeatured}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isFeatured &&
                              formik.touched.isFeatured
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isFeatured",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isFeatured}
                          />
                          <span className="drawer_span">
                            Is Featured{" "}
                            <span className="form-label-secondary">
                              (Display on navbar ?)
                            </span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                    {/*  */}
                    <div style={{ width: "100%" }}>
                      <span className="pb-2 drawer_span required">
                        Testimonial Content{" "}
                        <span className="form-label-secondary">(Arabic)</span>
                      </span>
                      <RichTextEditor
                        value={formik.values.reviewText_ar}
                        name="reviewText_ar"
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("reviewText_ar", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.reviewText_ar &&
                        formik.touched.reviewText_ar && (
                          <ErrorMessage message={formik.errors.reviewText_ar} />
                        )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "de" && (
                  <>
                    {" "}
                    <div className="row mx-3">
                      <div className="col mt-2"></div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span required">
                        Client Full Name{" "}
                        <span className="form-label-secondary">(German)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="clientName_de"
                        className={`form-control ${
                          formik.errors.clientName_de &&
                          formik.touched.clientName_de
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName_de}
                        placeholder="Enter client full name in German"
                      />
                      {formik.errors.clientName_de &&
                        formik.touched.clientName_de && (
                          <ErrorMessage message={formik.errors.clientName_de} />
                        )}
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Designation{" "}
                          <span className="form-label-secondary">(German)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="clientDesignation_de"
                          className={`form-control ${
                            formik.errors.clientDesignation_de &&
                            formik.touched.clientDesignation_de
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.clientDesignation_de}
                          placeholder="Enter designation in German"
                        />
                        {formik.errors.clientDesignation_de &&
                          formik.touched.clientDesignation_de && (
                            <ErrorMessage
                              message={formik.errors.clientDesignation_de}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Company Name{" "}
                          <span className="form-label-secondary">(German)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyName_de"
                          className={`form-control ${
                            formik.errors.companyName_de &&
                            formik.touched.companyName_de
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName_de}
                          placeholder="Enter Company Name in German"
                        />
                        {formik.errors.companyName_de &&
                          formik.touched.companyName_de && (
                            <ErrorMessage
                              message={formik.errors.companyName_de}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span">
                        Company Type{" "}
                        <span className="form-label-secondary">(German)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="companyType_de"
                        className={`form-control ${
                          formik.errors.companyType_de &&
                          formik.touched.companyType_de
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyType_de}
                        placeholder="Enter what the company is about in German"
                      />
                      {formik.errors.companyType_de &&
                        formik.touched.companyType_de && (
                          <ErrorMessage
                            message={formik.errors.companyType_de}
                          />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Company Website Link
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyWebsiteLink"
                          className={`form-control ${
                            formik.errors.companyWebsiteLink &&
                            formik.touched.companyWebsiteLink
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyWebsiteLink}
                          placeholder="Enter link of company website"
                        />
                        {formik.errors.companyWebsiteLink &&
                          formik.touched.companyWebsiteLink && (
                            <ErrorMessage
                              message={formik.errors.companyWebsiteLink}
                            />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span ">Country</span>
                        <CountrySelect
                          values={{
                            value: code || countriesRecords[0]?.code || "",
                            label: (
                              <div>
                                {" "}
                                <img
                                  className="avatar avatar-xss avatar-circle me-2"
                                  src={`assets_modules/flag-icon-css/flags/1x1/${
                                    code?.toLowerCase() ||
                                    editRecord?.country?.code ||
                                    countriesRecords[0]?.code
                                  }.svg`}
                                  alt=" Country Flag"
                                />
                                {/* {country || countriesRecords[0]?.name_de} */}
                                {(code &&
                                  countriesRecords.find(
                                    (country) => country.code === code
                                  )?.[`name_${language}`]) ||
                                  countriesRecords[0]?.name_de}
                              </div>
                            ),
                          }}
                          defaultNameRedux={""}
                          handleBlur={formik.handleBlur}
                          defaultValueRedux={""}
                          setCountry={(e) => {
                            formik.setFieldValue("countryId", e?.id || "");
                            // setCountry(e?.value || "");
                            setCode(
                              e?.label?.props?.children[0]?.props?.alt || ""
                            );
                          }}
                          className={
                            "w-100" +
                            ` ${
                              formik.errors.countryId &&
                              formik.touched.countryId
                                ? "input_error"
                                : ""
                            }`
                          }
                          style={{
                            border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                          }}
                          missingRecords={countriesRecords}
                          isDisabled={view}
                          language={language}
                        />
                        {formik.errors.countryId &&
                          formik.touched.countryId && (
                            <ErrorMessage message={formik.errors.countryId} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Date time *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Source</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewSource"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewSource", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewSource || "self"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Source
                          </option>
                          <option value="self">Best Clinic Aboard</option>
                          <option value="google">Google</option>
                          <option value="trustpilot">Trustpilot</option>

                        </select>
                        {formik.errors.reviewSource &&
                          formik.touched.reviewSource && (
                            <ErrorMessage message={formik.errors.reviewSource} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Type</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewType"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewType", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewType || "text"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Type
                          </option>
                          <option value="text">Written Testimonials</option>
                          <option value="video">Video Testimonials</option>
                        </select>
                        {formik.errors.reviewType &&
                          formik.touched.reviewType && (
                            <ErrorMessage message={formik.errors.reviewType} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {formik.values.reviewType === "video" && (
                      <div className="mb-5 pb-4">
                        <span className="pb-2  drawer_span">
                          Upload Video Testimonials
                          {previewUrl && !uploaderLoading && (
                            <button
                              className="btn btn-outline-dark mx-3"
                              type="button"
                              onClick={() => setPreviewOpen(true)}
                            >
                              Preview Video
                            </button>
                          )}
                        </span>
                        <div
                          className="d-flex align-items-center h-75  text-center"
                          style={{
                            border: "dotted 3px  grey",
                            placeContent: "center",
                          }}
                        >
                          <Upload
                            disabled={view}
                            action=""
                            customRequest={({ file }) => handleUpload(file)}
                            showUploadList={false}
                          >
                            {uploadButton}
                            {uploaderLoading && (
                              <>
                                {" "}
                                <Spin />{" "}
                                <span>Uploading Video, Please Wait</span>{" "}
                              </>
                            )}
                          </Upload>

                          <Modal
                            title="Preview Video"
                            visible={previewOpen}
                            footer={null}
                            onCancel={handleCancel}
                            bodyStyle={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "70vh",
                            }}
                          >
                            <video
                              src={previewUrl}
                              controls
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </Modal>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">Rating</span>
                        <select
                          disabled={view}
                          className={`form-control ${
                            formik.errors.rating && formik.touched.rating
                              ? "input_error"
                              : ""
                          }`}
                          name="rating"
                          onChange={(e) => {
                            formik.setFieldValue("rating", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            parseFloat(formik.values.rating) ||
                            parseFloat(editRecord?.rating) ||
                            ""
                          }
                        >
                          <option value="">Select rating</option>
                          <option value={0.5}>0.5</option>
                          <option value={1.0}>1.0</option>
                          <option value={1.5}>1.5</option>
                          <option value={2.0}>2.0</option>
                          <option value={2.5}>2.5</option>
                          <option value={3.0}>3.0</option>
                          <option value={3.5}>3.5</option>
                          <option value={4.0}>4.0</option>
                          <option value={4.5}>4.5</option>
                          <option value={5.0}>5.0</option>
                        </select>
                        {formik.errors.rating && formik.touched.rating && (
                          <ErrorMessage message={formik.errors.rating} />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial From</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewFrom"
                          onChange={(e) => {
                            setReviewFrom(e.target.value);
                          }}
                          value={reviewFrom || ""}
                        >
                          <option value="">Select Testimonials From</option>
                          <option value="Facility">Facility</option>
                          <option value="Doctor">Doctor</option>
                        </select>
                        {showErrorReviewFrom && (
                          <ErrorMessage
                            message={"Testimonials From is required"}
                          />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      {reviewFrom === "Facility" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Facility</span>
                          <select
                            disabled={view}
                            className="form-control drawer_input"
                            name="facilityId"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "facilityId",
                                e.target.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.facilityId}
                          >
                            <option value={null}> Select Facility</option>
                            {facility?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.name_de}
                              </option>
                            ))}
                          </select>
                          {formik.errors.facilityId &&
                            formik.touched.facilityId && (
                              <ErrorMessage
                                message={formik.errors.facilityId}
                              />
                            )}
                        </div>
                      )}
                      {/* /********** INPUT ********/}
                      {reviewFrom === "Doctor" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Doctor</span>
                          <select
                            defaultValue={null}
                            disabled={view}
                            className="form-control drawer_input"
                            name="doctorId"
                            onChange={(e) => {
                              const selectedDoctorId =
                                e.target.value === "null"
                                  ? null
                                  : e.target.value;
                              formik.setFieldValue(
                                "doctorId",
                                selectedDoctorId
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.doctorId}
                          >
                            <option value={null}> Select Doctor</option>
                            {doctor?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.firstName_de +
                                  " " +
                                  record?.lastName_de}
                              </option>
                            ))}
                          </select>
                          {formik.errors.doctorId &&
                            formik.touched.doctorId && (
                              <ErrorMessage message={formik.errors.doctorId} />
                            )}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    {/* /***************** isApproved *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isApproved"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isApproved}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isApproved &&
                              formik.touched.isApproved
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isApproved",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isApproved}
                          />
                          <span className="drawer_span">
                            Is Approved{" "}
                            <span className="form-label-secondary"></span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isApproved &&
                        formik.touched.isApproved && (
                          <ErrorMessage message={formik.errors.isApproved} />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isFeatured"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isFeatured}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isFeatured &&
                              formik.touched.isFeatured
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isFeatured",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isFeatured}
                          />
                          <span className="drawer_span">
                            Is Featured{" "}
                            <span className="form-label-secondary">
                              (Display on navbar ?)
                            </span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                    {/*  */}
                    <div style={{ width: "100%" }}>
                      <span className="pb-2 drawer_span required">
                        Testimonial Content{" "}
                        <span className="form-label-secondary">(German)</span>
                      </span>
                      <RichTextEditor
                        value={formik.values.reviewText_de}
                        name="reviewText_de"
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("reviewText_de", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.reviewText_de &&
                        formik.touched.reviewText_de && (
                          <ErrorMessage message={formik.errors.reviewText_de} />
                        )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "ru" && (
                  <>
                    {" "}
                    <div className="row mx-3">
                      <div className="col mt-2"></div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span required">
                        Client Full Name{" "}
                        <span className="form-label-secondary">(Russian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="clientName_ru"
                        className={`form-control ${
                          formik.errors.clientName_ru &&
                          formik.touched.clientName_ru
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName_ru}
                        placeholder="Enter client full name in Russian"
                      />
                      {formik.errors.clientName_ru &&
                        formik.touched.clientName_ru && (
                          <ErrorMessage message={formik.errors.clientName_ru} />
                        )}
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Designation{" "}
                          <span className="form-label-secondary">
                            (Russian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="clientDesignation_ru"
                          className={`form-control ${
                            formik.errors.clientDesignation_ru &&
                            formik.touched.clientDesignation_ru
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.clientDesignation_ru}
                          placeholder="Enter designation in Russian"
                        />
                        {formik.errors.clientDesignation_ru &&
                          formik.touched.clientDesignation_ru && (
                            <ErrorMessage
                              message={formik.errors.clientDesignation_ru}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Company Name{" "}
                          <span className="form-label-secondary">
                            (Russian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyName_ru"
                          className={`form-control ${
                            formik.errors.companyName_ru &&
                            formik.touched.companyName_ru
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName_ru}
                          placeholder="Enter Company Name in Russian"
                        />
                        {formik.errors.companyName_ru &&
                          formik.touched.companyName_ru && (
                            <ErrorMessage
                              message={formik.errors.companyName_ru}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span ">
                        Company Type{" "}
                        <span className="form-label-secondary">(Russian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="companyType_ru"
                        className={`form-control ${
                          formik.errors.companyType_ru &&
                          formik.touched.companyType_ru
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyType_ru}
                        placeholder="Enter what the company is about in Russian"
                      />
                      {formik.errors.companyType_ru &&
                        formik.touched.companyType_ru && (
                          <ErrorMessage
                            message={formik.errors.companyType_ru}
                          />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Company Website Link
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyWebsiteLink"
                          className={`form-control ${
                            formik.errors.companyWebsiteLink &&
                            formik.touched.companyWebsiteLink
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyWebsiteLink}
                          placeholder="Enter link of company website"
                        />
                        {formik.errors.companyWebsiteLink &&
                          formik.touched.companyWebsiteLink && (
                            <ErrorMessage
                              message={formik.errors.companyWebsiteLink}
                            />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Country</span>
                        <CountrySelect
                          values={{
                            value: code || countriesRecords[0]?.code || "",
                            label: (
                              <div>
                                {" "}
                                <img
                                  className="avatar avatar-xss avatar-circle me-2"
                                  src={`assets_modules/flag-icon-css/flags/1x1/${
                                    code?.toLowerCase() ||
                                    editRecord?.country?.code ||
                                    countriesRecords[0]?.code
                                  }.svg`}
                                  alt=" Country Flag"
                                />
                                {/* {country || countriesRecords[0]?.name_ru} */}
                                {(code &&
                                  countriesRecords.find(
                                    (country) => country.code === code
                                  )?.[`name_${language}`]) ||
                                  countriesRecords[0]?.name_ru}
                              </div>
                            ),
                          }}
                          defaultNameRedux={""}
                          handleBlur={formik.handleBlur}
                          defaultValueRedux={""}
                          setCountry={(e) => {
                            formik.setFieldValue("countryId", e?.id || "");
                            // setCountry(e?.value || "");
                            setCode(
                              e?.label?.props?.children[0]?.props?.alt || ""
                            );
                          }}
                          className={
                            "w-100" +
                            ` ${
                              formik.errors.countryId &&
                              formik.touched.countryId
                                ? "input_error"
                                : ""
                            }`
                          }
                          style={{
                            border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                          }}
                          missingRecords={countriesRecords}
                          isDisabled={view}
                          language={language}
                        />
                        {formik.errors.countryId &&
                          formik.touched.countryId && (
                            <ErrorMessage message={formik.errors.countryId} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Date time *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Source</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewSource"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewSource", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewSource || "self"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Source
                          </option>
                          <option value="self">Best Clinic Aboard</option>
                          <option value="google">Google</option>
                          <option value="trustpilot">Trustpilot</option>

                        </select>
                        {formik.errors.reviewSource &&
                          formik.touched.reviewSource && (
                            <ErrorMessage message={formik.errors.reviewSource} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Type</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewType"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewType", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewType || "text"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Type
                          </option>
                          <option value="text">Written Testimonials</option>
                          <option value="video">Video Testimonials</option>
                        </select>
                        {formik.errors.reviewType &&
                          formik.touched.reviewType && (
                            <ErrorMessage message={formik.errors.reviewType} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {formik.values.reviewType === "video" && (
                      <div className="mb-5 pb-4">
                        <span className="pb-2  drawer_span">
                          Upload Video Testimonials
                          {previewUrl && !uploaderLoading && (
                            <button
                              className="btn btn-outline-dark mx-3"
                              type="button"
                              onClick={() => setPreviewOpen(true)}
                            >
                              Preview Video
                            </button>
                          )}
                        </span>
                        <div
                          className="d-flex align-items-center h-75  text-center"
                          style={{
                            border: "dotted 3px  grey",
                            placeContent: "center",
                          }}
                        >
                          <Upload
                            disabled={view}
                            action=""
                            customRequest={({ file }) => handleUpload(file)}
                            showUploadList={false}
                          >
                            {uploadButton}
                            {uploaderLoading && (
                              <>
                                {" "}
                                <Spin />{" "}
                                <span>Uploading Video, Please Wait</span>{" "}
                              </>
                            )}
                          </Upload>

                          <Modal
                            title="Preview Video"
                            visible={previewOpen}
                            footer={null}
                            onCancel={handleCancel}
                            bodyStyle={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "70vh",
                            }}
                          >
                            <video
                              src={previewUrl}
                              controls
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </Modal>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">Rating</span>
                        <select
                          disabled={view}
                          className={`form-control ${
                            formik.errors.rating && formik.touched.rating
                              ? "input_error"
                              : ""
                          }`}
                          name="rating"
                          onChange={(e) => {
                            formik.setFieldValue("rating", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            parseFloat(formik.values.rating) ||
                            parseFloat(editRecord?.rating) ||
                            ""
                          }
                        >
                          <option value="">Select rating</option>
                          <option value={0.5}>0.5</option>
                          <option value={1.0}>1.0</option>
                          <option value={1.5}>1.5</option>
                          <option value={2.0}>2.0</option>
                          <option value={2.5}>2.5</option>
                          <option value={3.0}>3.0</option>
                          <option value={3.5}>3.5</option>
                          <option value={4.0}>4.0</option>
                          <option value={4.5}>4.5</option>
                          <option value={5.0}>5.0</option>
                        </select>
                        {formik.errors.rating && formik.touched.rating && (
                          <ErrorMessage message={formik.errors.rating} />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span ">Testimonial From</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewFrom"
                          onChange={(e) => {
                            setReviewFrom(e.target.value);
                          }}
                          value={reviewFrom || ""}
                        >
                          <option value="">Select Testimonials From</option>
                          <option value="Facility">Facility</option>
                          <option value="Doctor">Doctor</option>
                        </select>
                        {showErrorReviewFrom && (
                          <ErrorMessage
                            message={"Testimonials From is required"}
                          />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      {reviewFrom === "Facility" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Facility</span>
                          <select
                            disabled={view}
                            className="form-control drawer_input"
                            name="facilityId"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "facilityId",
                                e.target.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.facilityId}
                          >
                            <option value={null}> Select Facility</option>
                            {facility?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.name_ru}
                              </option>
                            ))}
                          </select>
                          {formik.errors.facilityId &&
                            formik.touched.facilityId && (
                              <ErrorMessage
                                message={formik.errors.facilityId}
                              />
                            )}
                        </div>
                      )}
                      {/* /********** INPUT ********/}
                      {reviewFrom === "Doctor" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Doctor</span>
                          <select
                            defaultValue={null}
                            disabled={view}
                            className="form-control drawer_input"
                            name="doctorId"
                            onChange={(e) => {
                              const selectedDoctorId =
                                e.target.value === "null"
                                  ? null
                                  : e.target.value;
                              formik.setFieldValue(
                                "doctorId",
                                selectedDoctorId
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.doctorId}
                          >
                            <option value={null}> Select Doctor</option>
                            {doctor?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.firstName_ru +
                                  " " +
                                  record?.lastName_ru}
                              </option>
                            ))}
                          </select>
                          {formik.errors.doctorId &&
                            formik.touched.doctorId && (
                              <ErrorMessage message={formik.errors.doctorId} />
                            )}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    {/* /***************** isApproved *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isApproved"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isApproved}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isApproved &&
                              formik.touched.isApproved
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isApproved",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isApproved}
                          />
                          <span className="drawer_span">
                            Is Approved{" "}
                            <span className="form-label-secondary"></span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isApproved &&
                        formik.touched.isApproved && (
                          <ErrorMessage message={formik.errors.isApproved} />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isFeatured"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isFeatured}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isFeatured &&
                              formik.touched.isFeatured
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isFeatured",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isFeatured}
                          />
                          <span className="drawer_span">
                            Is Featured{" "}
                            <span className="form-label-secondary">
                              (Display on navbar ?)
                            </span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                    {/*  */}
                    <div style={{ width: "100%" }}>
                      <span className="pb-2 drawer_span required">
                        Testimonial Content{" "}
                        <span className="form-label-secondary">(Russian)</span>
                      </span>
                      <RichTextEditor
                        value={formik.values.reviewText_ru}
                        name="reviewText_ru"
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("reviewText_ru", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.reviewText_ru &&
                        formik.touched.reviewText_ru && (
                          <ErrorMessage message={formik.errors.reviewText_ru} />
                        )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "uk" && (
                  <>
                    {" "}
                    <div className="row mx-3">
                      <div className="col mt-2"></div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span required">
                        Client Full Name{" "}
                        <span className="form-label-secondary">
                          (Ukrainian)
                        </span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="clientName_uk"
                        className={`form-control ${
                          formik.errors.clientName_uk &&
                          formik.touched.clientName_uk
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.clientName_uk}
                        placeholder="Enter client full name in Ukrainian"
                      />
                      {formik.errors.clientName_uk &&
                        formik.touched.clientName_uk && (
                          <ErrorMessage message={formik.errors.clientName_uk} />
                        )}
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Designation{" "}
                          <span className="form-label-secondary">
                            (Ukrainian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="clientDesignation_uk"
                          className={`form-control ${
                            formik.errors.clientDesignation_uk &&
                            formik.touched.clientDesignation_uk
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.clientDesignation_uk}
                          placeholder="Enter designation in Ukrainian"
                        />
                        {formik.errors.clientDesignation_uk &&
                          formik.touched.clientDesignation_uk && (
                            <ErrorMessage
                              message={formik.errors.clientDesignation_uk}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">
                          Company Name{" "}
                          <span className="form-label-secondary">
                            (Ukrainian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyName_uk"
                          className={`form-control ${
                            formik.errors.companyName_uk &&
                            formik.touched.companyName_uk
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName_uk}
                          placeholder="Enter Company Name in Ukrainian"
                        />
                        {formik.errors.companyName_uk &&
                          formik.touched.companyName_uk && (
                            <ErrorMessage
                              message={formik.errors.companyName_uk}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <span className="drawer_span ">
                        Company Type{" "}
                        <span className="form-label-secondary">
                          (Ukrainian)
                        </span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="companyType_uk"
                        className={`form-control ${
                          formik.errors.companyType_uk &&
                          formik.touched.companyType_uk
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyType_uk}
                        placeholder="Enter what the company is about in Ukrainian"
                      />
                      {formik.errors.companyType_uk &&
                        formik.touched.companyType_uk && (
                          <ErrorMessage
                            message={formik.errors.companyType_uk}
                          />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">
                          Company Website Link
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="companyWebsiteLink"
                          className={`form-control ${
                            formik.errors.companyWebsiteLink &&
                            formik.touched.companyWebsiteLink
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyWebsiteLink}
                          placeholder="Enter link of company website"
                        />
                        {formik.errors.companyWebsiteLink &&
                          formik.touched.companyWebsiteLink && (
                            <ErrorMessage
                              message={formik.errors.companyWebsiteLink}
                            />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Country</span>
                        <CountrySelect
                          values={{
                            value: code || countriesRecords[0]?.code || "",
                            label: (
                              <div>
                                {" "}
                                <img
                                  className="avatar avatar-xss avatar-circle me-2"
                                  src={`assets_modules/flag-icon-css/flags/1x1/${
                                    code?.toLowerCase() ||
                                    editRecord?.country?.code ||
                                    countriesRecords[0]?.code
                                  }.svg`}
                                  alt=" Country Flag"
                                />
                                {/* {country || countriesRecords[0]?.name_uk} */}
                                {(code &&
                                  countriesRecords.find(
                                    (country) => country.code === code
                                  )?.[`name_${language}`]) ||
                                  countriesRecords[0]?.name_uk}
                              </div>
                            ),
                          }}
                          defaultNameRedux={""}
                          handleBlur={formik.handleBlur}
                          defaultValueRedux={""}
                          setCountry={(e) => {
                            formik.setFieldValue("countryId", e?.id || "");
                            // setCountry(e?.value || "");
                            setCode(
                              e?.label?.props?.children[0]?.props?.alt || ""
                            );
                          }}
                          className={
                            "w-100" +
                            ` ${
                              formik.errors.countryId &&
                              formik.touched.countryId
                                ? "input_error"
                                : ""
                            }`
                          }
                          style={{
                            border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                          }}
                          missingRecords={countriesRecords}
                          isDisabled={view}
                          language={language}
                        />
                        {formik.errors.countryId &&
                          formik.touched.countryId && (
                            <ErrorMessage message={formik.errors.countryId} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Date time *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Date & Time</span>
                        <input
                        disabled={view}
                        className={`form-control ${
                          formik.errors.createdAt &&
                          formik.touched.createdAt
                            ? "input_error"
                            : ""
                        }`}
                        onBlur={formik.handleBlur}
                        value={formatForDatetimeLocal(formik.values.createdAt)}
                        placeholder="Enter Date and time"
                        type="datetime-local"
                        id="createdAt"
                        name="createdAt"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue("createdAt", e.target.value);
                        }}
                      />
                        {formik.errors.createdAt &&
                          formik.touched.createdAt && (
                            <ErrorMessage message={formik.errors.createdAt} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Source</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewSource"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewSource", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewSource || "self"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Source
                          </option>
                          <option value="self">Best Clinic Aboard</option>
                          <option value="google">Google</option>
                          <option value="trustpilot">Trustpilot</option>

                        </select>
                        {formik.errors.reviewSource &&
                          formik.touched.reviewSource && (
                            <ErrorMessage message={formik.errors.reviewSource} />
                          )}
                      </div>
                    </div>
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span">Testimonial Type</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewType"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("reviewType", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.reviewType || "text"}
                        >
                          <option value={null} disabled>
                            Select Testimonials Type
                          </option>
                          <option value="text">Written Testimonials</option>
                          <option value="video">Video Testimonials</option>
                        </select>
                        {formik.errors.reviewType &&
                          formik.touched.reviewType && (
                            <ErrorMessage message={formik.errors.reviewType} />
                          )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {formik.values.reviewType === "video" && (
                      <div className="mb-5 pb-4">
                        <span className="pb-2  drawer_span">
                          Upload Video Testimonials
                          {previewUrl && !uploaderLoading && (
                            <button
                              className="btn btn-outline-dark mx-3"
                              type="button"
                              onClick={() => setPreviewOpen(true)}
                            >
                              Preview Video
                            </button>
                          )}
                        </span>
                        <div
                          className="d-flex align-items-center h-75  text-center"
                          style={{
                            border: "dotted 3px  grey",
                            placeContent: "center",
                          }}
                        >
                          <Upload
                            disabled={view}
                            action=""
                            customRequest={({ file }) => handleUpload(file)}
                            showUploadList={false}
                          >
                            {uploadButton}
                            {uploaderLoading && (
                              <>
                                {" "}
                                <Spin />{" "}
                                <span>Uploading Video, Please Wait</span>{" "}
                              </>
                            )}
                          </Upload>

                          <Modal
                            title="Preview Video"
                            visible={previewOpen}
                            footer={null}
                            onCancel={handleCancel}
                            bodyStyle={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "70vh",
                            }}
                          >
                            <video
                              src={previewUrl}
                              controls
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </Modal>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span required">Rating</span>
                        <select
                          disabled={view}
                          className={`form-control ${
                            formik.errors.rating && formik.touched.rating
                              ? "input_error"
                              : ""
                          }`}
                          name="rating"
                          onChange={(e) => {
                            formik.setFieldValue("rating", e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            parseFloat(formik.values.rating) ||
                            parseFloat(editRecord?.rating) ||
                            ""
                          }
                        >
                          <option value="">Select rating</option>
                          <option value={0.5}>0.5</option>
                          <option value={1.0}>1.0</option>
                          <option value={1.5}>1.5</option>
                          <option value={2.0}>2.0</option>
                          <option value={2.5}>2.5</option>
                          <option value={3.0}>3.0</option>
                          <option value={3.5}>3.5</option>
                          <option value={4.0}>4.0</option>
                          <option value={4.5}>4.5</option>
                          <option value={5.0}>5.0</option>
                        </select>
                        {formik.errors.rating && formik.touched.rating && (
                          <ErrorMessage message={formik.errors.rating} />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <span className="drawer_span ">Testimonial From</span>
                        <select
                          disabled={view}
                          className="form-control drawer_input"
                          name="reviewFrom"
                          onChange={(e) => {
                            setReviewFrom(e.target.value);
                          }}
                          value={reviewFrom || ""}
                        >
                          <option value="">Select Testimonials From</option>
                          <option value="Facility">Facility</option>
                          <option value="Doctor">Doctor</option>
                        </select>
                        {showErrorReviewFrom && (
                          <ErrorMessage
                            message={"Testimonials From is required"}
                          />
                        )}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      {reviewFrom === "Facility" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Facility</span>
                          <select
                            disabled={view}
                            className="form-control drawer_input"
                            name="facilityId"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "facilityId",
                                e.target.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.facilityId}
                          >
                            <option value={null}> Select Facility</option>
                            {facility?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.name_uk}
                              </option>
                            ))}
                          </select>
                          {formik.errors.facilityId &&
                            formik.touched.facilityId && (
                              <ErrorMessage
                                message={formik.errors.facilityId}
                              />
                            )}
                        </div>
                      )}
                      {/* /********** INPUT ********/}
                      {reviewFrom === "Doctor" && (
                        <div className="">
                          <span className="pb-2 drawer_span">Doctor</span>
                          <select
                            defaultValue={null}
                            disabled={view}
                            className="form-control drawer_input"
                            name="doctorId"
                            onChange={(e) => {
                              const selectedDoctorId =
                                e.target.value === "null"
                                  ? null
                                  : e.target.value;
                              formik.setFieldValue(
                                "doctorId",
                                selectedDoctorId
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.doctorId}
                          >
                            <option value={null}> Select Doctor</option>
                            {doctor?.map((record, index) => (
                              <option key={index} value={record?.id}>
                                {" "}
                                {record?.firstName_uk +
                                  " " +
                                  record?.lastName_uk}
                              </option>
                            ))}
                          </select>
                          {formik.errors.doctorId &&
                            formik.touched.doctorId && (
                              <ErrorMessage message={formik.errors.doctorId} />
                            )}
                        </div>
                      )}
                    </div>
                    {/*  */}
                    {/* /***************** isApproved *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isApproved"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isApproved}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isApproved &&
                              formik.touched.isApproved
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isApproved",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isApproved}
                          />
                          <span className="drawer_span">
                            Is Approved{" "}
                            <span className="form-label-secondary"></span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isApproved &&
                        formik.touched.isApproved && (
                          <ErrorMessage message={formik.errors.isApproved} />
                        )}
                    </div>
                    {/*  */}
                    {/* /***************** Title *******************/}
                    <div style={{ width: "100%" }}>
                      <div className="">
                        <div className="form-check form-switch my-2">
                          <input
                            disabled={view}
                            name="isFeatured"
                            type="checkbox"
                            role="switch"
                            checked={formik.values.isFeatured}
                            className={` drawer_input form-check-input drawer_input ${
                              formik.errors.isFeatured &&
                              formik.touched.isFeatured
                                ? "input_error"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "isFeatured",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.isFeatured}
                          />
                          <span className="drawer_span">
                            Is Featured{" "}
                            <span className="form-label-secondary">
                              (Display on navbar ?)
                            </span>
                          </span>
                        </div>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                    {/*  */}
                    <div style={{ width: "100%" }}>
                      <span className="pb-2 drawer_span required">
                        Testimonial Content{" "}
                        <span className="form-label-secondary">
                          (Ukrainian)
                        </span>
                      </span>
                      <RichTextEditor
                        value={formik.values.reviewText_uk}
                        name="reviewText_uk"
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("reviewText_uk", value)
                        }
                        disabled={view}
                      />
                      {formik.errors.reviewText_uk &&
                        formik.touched.reviewText_uk && (
                          <ErrorMessage message={formik.errors.reviewText_uk} />
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default TestimonialsDrawer;
