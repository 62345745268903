import axios from "axios";
import { ApiUrl } from "services/api";

const { Testimonials: Api } = ApiUrl;

export const getAll = async ({ query }) =>
  axios[Api.getAll.method](Api.getAll.url(query));

export const create = async (data) =>
  axios[Api.create.method](Api.create.url(), data);

export const remove = async (id) =>
  axios[Api.remove.method](Api.remove.url(id));

export const update = async (id, data) =>
  axios[Api.update.method](Api.update.url(id), data);

// export const UploadVideoToS3 = async (s3Url,file ) => {
//   try {
//     const baseUrl =process.env.REACT_APP_API_URL;
//       const uploadResponse = await fetch(`${baseUrl}/general/upload`, {

//       method: "POST",
//       headers: {
//         "Content-Type": file.type,
//       },
//       body: file,
//     });
//     console.log("uploadResponse:",uploadResponse)

//     if (uploadResponse.ok) {
//     } else {
//       throw new Error("Failed to upload video");
//     }
//   } catch (error) {
//     console.error("Error uploading video:", error);
//   }
// };

