export const userRelations = [];
export const treatmentRelations = ['speciality'];
export const specialityRelations = [];
export const countryRelations = [];
export const blogRelations = [];
export const teamRelations = [];
export const contentRelations = ["city","country",'speciality', "treatment","disease"];

export const testimonialRelations = ["country"
  //  ,"country.slug"
  ];

export const cityRelations = ["country", "slug"];

export const requestRelations = [
  // "requestPayment.country",
  // "requestPayment.treatment",
  // "requestPayment",
  // "requestPayment.user",
  // "requestComment",
  // "requestComment.user",
  // "media",
  "requestLog",
  "requestLog.user",
  "user",
  "requestEvent"
];

export const doctorRelations = [
  "slug",
  "city",
  "city.country",
  "doctorSpeciality",
  "doctorSpeciality.speciality",
  "doctorServiceLanguage",
  "doctorServiceLanguage.serviceLanguage",
  "doctorFaq",
  "doctorFaq.faq",
];
export const facilitySpecialityRelations=[
  "facilitySpeciality",
  "media"
];

export const facilityRelations = [
  // "slug",
  "city",
  // "achievements",
  // "facilityWhyChooseUs",
  // "facilityServicePrice",
  // "facilityService",
  // "facilityDoctor",
  // "facilityFaq",
  // "facilityDepartment",
  // "facilitySpeciality",
  // "facilityServiceLanguage",
  "media",
];

export const diseasesRelations = [
  'slug',
  'treatmentDisease', 
  'treatmentDisease.treatment', 
  'treatmentDisease.treatment.speciality',
];