const baseUrl =
  // "https://api-bestclinicabroad.doctors-finder.com" ||
  process.env.REACT_APP_API_URL;

const Method = {
  POST: "post",
  GET: "get",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const ApiUrl = {
  General: {
    statistics: {
      method: Method.GET,
      url: () => `${baseUrl}/general/statistics`,
    },
    upload: {
      method: Method.POST,
      url: () => `${baseUrl}/general/upload`,
    },
  },
  User: {
    // logIn: { url: () => `${baseUrl}/user/login`, method: Method.POST },
    logIn: { url: () => `${baseUrl}/user/login`, method: Method.GET },
    updatePassword: {
      url: () => `${baseUrl}/user/update-password`,
      method: Method.POST,
    },
    signUp: {
      url: () => `${baseUrl}/user/signup`,
      method: Method.POST,
    },
    update: {
      url: (id) => `${baseUrl}/user/${id}`,
      method: Method.PATCH,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/user?${query}`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/user/${id}`,
    },
  },
  Country: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/country/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/country`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/country/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/country/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/country?${query}`,
    },
    getAllTheCountries: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/country/get/all?${query}`,
    },
    getAllRelated: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/country?${query}`,
    },
    getGeneralContent: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/general/content?${query}`,
    },
    createGeneralContent: {
      method: Method.PUT,
      url: () => `${baseUrl}/general/content`,
    },
  },
  City: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/city/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/city`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/city/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/city/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/city?${query}`,
    },
  },
  Treatment: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/treatment/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/treatment`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/treatment/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/treatment?${query}`,
    },
  },
  Speciality: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/speciality/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/speciality`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/speciality/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/speciality/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/speciality?${query}`,
    },
  },
  Content: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/content/${id}`,
    },
    updateDraft: {
      method: Method.POST,
      url: (id) => `${baseUrl}/content/approve/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/content`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/content/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/content?${query}`,
    },
    getEditContent: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/content?${query}`,
    },
    getAllDraft: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/content?draftContent=true`,
    },
    contentFilters: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/content/getByRelations?${query}`,
    },
  },
  Disease: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/disease/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/disease`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/disease/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/disease?${query}`,
    },
  },
  Slug: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/slug/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/slug?${query}`,
    },
    getAllMissing: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/slug/missing/slug?tableName=${query}&idField=${query}_id`,
    },
    createTheMissing: {
      method: Method.POST,
      url: (id) => `${baseUrl}/slug/bulk`,
    },
  },
  Settings: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/setting/${id}`, 
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/setting`,
    },
  },
  ServiceLanguage: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/common-service-language/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/common-service-language`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/common-service-language/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/common-service-language/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/common-service-language?${query}`,
    },
  },
  Service: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/common-service/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/common-service`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/common-service/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/common-service/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/common-service?${query}`,
    },
  },
  Faqs: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/common-faq/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/common-faq`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/common-faq/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/common-faq/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/common-faq?${query}`,
    },
  },
  ServicePrice: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/common-service-price/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/common-service-price`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/common-service-price/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/common-service-price/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/common-service-price?${query}`,
    },
  },
  WhyChooseUs: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/common-why-choose-us/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/common-why-choose-us`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/common-why-choose-us/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/common-why-choose-us/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/common-why-choose-us?${query}`,
    },
  },
  FacilityDepartment: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/department/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/department`,
    },
    createRelation: {
      method: Method.POST,
      url: () => `${baseUrl}/facility-department`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/department/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/department/${id}`,
    },
    
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/department?${query}`,
    },
  },
  FacilitySpeciality: {
    updateFacilitySpeciality: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/facility-speciality/${id}`,

    },
    createFacilitySpeciality: {
      method: Method.POST,
      url: () => `${baseUrl}/facility-speciality`,
    },
    removeFacilitySpecility: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/facility-speciality/${id}`,

    },
    
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/speciality/${id}`,

    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/speciality`,

    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/speciality/bulk`,

    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/speciality/${id}`,

    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/speciality?${query}`,

    },
    getAllFaciltiesSpecialities: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/facility-speciality?${query}`;
      },
    },
  //   getAll: {
  //     method: Method.GET,
  //     url: (query = ``) => {
  //         return `${baseUrl}/facility?${query}`;
  //     },
  // },
  
  },
  Doctor: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/doctor/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/doctor`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/doctor/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/doctor?${query}`,
    },
    getAllRelated: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/doctor?${query}`,
    },
    file: {
      method: Method.POST,
      url: () => `${baseUrl}/doctor/file`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/doctor/bulk`,
    },
  },
  Blog: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/blog/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/blog`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/blog/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/blog?${query}`;
      },
    },
  },
  Testimonials: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/review/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/review`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/review/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => `${baseUrl}/review?${query}`,
    },
  
  },
  Facility: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/facility/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/facility`,
    },
    bulk: {
      method: Method.POST,
      url: () => `${baseUrl}/facility/bulk`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/facility/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/facility?${query}`;
      },
    },
    getAllAchievements: {
        method: Method.GET,
        url: (query = ``) => {
          return `${baseUrl}/achievement?${query}`;
        },
    },
  },
  Request: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/request/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/request`,
    },
    createEvent: {
      method: Method.POST,
      url: () => `${baseUrl}/request-event`,
    },
    createEventCompleted: {
      method: Method.POST,
      url: (id) => `${baseUrl}/request/complete/${id}`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/request/${id}`,
    },
    removeComment: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/request-comment/${id}`,
    },
    updateComment: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/request-comment/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/request?${query}`;
      },
    },
    getRequestComment: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/request-comment?${query}`;
      },
    },
    getRequestPayment: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/request-payment?${query}`;
      },
    },
    comment: {
      method: Method.POST,
      url: () => `${baseUrl}/request-comment`,
    },
    payment: {
      method: Method.POST,
      url: () => `${baseUrl}/request-payment`,
    },
   
  },
  Team: {
    update: {
      method: Method.PATCH,
      url: (id) => `${baseUrl}/team/${id}`,
    },
    create: {
      method: Method.POST,
      url: () => `${baseUrl}/team`,
    },
    remove: {
      method: Method.DELETE,
      url: (id) => `${baseUrl}/team/${id}`,
    },
    getAll: {
      method: Method.GET,
      url: (query = ``) => {
        return `${baseUrl}/team?${query}`;
      },
    },
   
  },
};
