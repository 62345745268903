import React, { useEffect, useState } from "react";
import Select from "react-select";
import countriesJson from "../../assets/master-data/countires.json";
import "./CountrySelect.style.css";

const style = {
  control: (base) => ({
    ...base,
    border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
    paddingTop: "5px",
    paddingBottom: "5px",
  }),
};

const CountrySelect = ({
  setCountry,
  className,
  values,
  handleBlur,
  isDisabled ,
  missingRecords,
  language
}) => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const options = missingRecords && missingRecords.length > 0
      ? missingRecords.map(record => ({
          // value: record.id,
          value:  record[`name_${language}`] ,
          id:record.id,
          label: (
            <div className="text-capitalize">
              <img
                className="avatar avatar-xss avatar-circle me-2 rounded-circle"
                src={`assets_modules/flag-icon-css/flags/1x1/${record.code?.toLowerCase()}.svg`}
                alt={`${record.code}`}
              />
                {record[`name_${language}`]}
            </div>
          ),
        }))
      : countriesJson.map(country => ({
          value: "country.name",
          label: (
            <div className="text-capitalize-first">
              <img
                className="avatar avatar-xss avatar-circle me-2 rounded-circle"
                src={`assets_modules/flag-icon-css/flags/1x1/${country.code?.toLowerCase()}.svg`}
                alt={`${country.code}`}
              />
                 {country.name
                .split(' ') // Split the name into an array of words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                .join(' ')} {/* Join the capitalized words back into a string */}
            </div>
          ),
        }));
    
    setCountries(options);
  }, [missingRecords,language]);

  
  return (
    <Select
      isDisabled={isDisabled}
      onBlur={handleBlur}
      name="country"
      value={values}
      onChange={setCountry}
      className={className}
      styles={style}
      options={countries}
    />
  );
};

export default CountrySelect;
