import { notification } from "antd";
import { CRUD_COMMON_MESSAGE } from "utils/constants/contants";
import * as requestFromServer from "./Crud";
import { countrySlice } from "./Reducer";
const { actions } = countrySlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};

export const getAllTheCountries = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAllTheCountries: true }));
  return requestFromServer
    .getAllTheCountries(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAllTheCountries({ records, count }));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAllTheCountries: false })));
};
export const getAll = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAll({ records, count }));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAll: false })));
};


export const getAllRelated = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAllRelated: true }));
  return requestFromServer
    .getAllRelated(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAllRelated({ records, count }));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAllRelated: false })));
};


export const getGlobalContent = (query) => async (dispatch) => {
  dispatch(actions.setLoading({ getGlobal: true }));
  try {
    const response = await requestFromServer.getGlobalContent(query);
    dispatch(actions.getGlobal(response.data)); 
    dispatch(actions.setLoading({ getGlobal: false }));
    return response.data;
  } catch (error) {
    console.error("Error fetching global content:", error);
    dispatch(actions.setLoading({ getGlobal: false }));
    throw error;
  }
};


export const create =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .create(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ create: false })));
  };
  
  
export const createGlobal =
(data, setDrawer, resetForm, pagination) => (dispatch) => {
  dispatch(actions.setLoading({ create: true }));
  return requestFromServer
    .createGlobalContent(data)
    .then(({ data }) => {
      if (data) {
        notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
        dispatch(getAll(pagination));
        setDrawer(false);
        resetForm();
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ create: false })));
};


export const bulk = (data, pagination) => (dispatch) => {
  return requestFromServer
    .bulk(data)
    .then(({ data }) => data && dispatch(getAll(pagination)))
    .catch(() => {})
    .finally(() => {});
};

export const remove = (id, setDeleteRecord, pagination) => (dispatch) => {
  dispatch(actions.setLoading({ remove: true }));
  return requestFromServer
    .remove(id)
    .then(({ data }) => {
      if (data) {
        notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
        dispatch(getAll(pagination));
        setDeleteRecord(null);
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ remove: false })));
};

export const update =
  (id, data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ update: true }));
    return requestFromServer
      .update(id, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch(() => {})
      .finally(() => dispatch(actions.setLoading({ update: false })));
  };
